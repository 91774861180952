<template>
  <v-avatar :color="color" :size="size">
    <span class="white--text">{{ storeInitials }}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    brand: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: [Number, String],
      default: "",
    },
    storeInitials: {
      type: String,
      default: "",
    },
  },
  computed: {
    color() {
      switch (this.brand) {
        case "PC":
          return "#a2007d";
        case "OUAC":
          return "#d4272a";
        case "PIAS":
          return "#f79429";
        case "SE":
          return "#c10230";
        case "MGR":
          return "#f6a914";
        default:
          return "#666666";
      }
    },
  },
};
</script>
